import React, { Fragment,useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
// import Dropzone from 'react-dropzone-uploader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import DatePicker from "react-datepicker";
import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import { addNewProject } from '../../../redux/project-app/action'
import { useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import axios from '../../../pages/services/api/axios'
import { ClientName, UploadProjectFile,Images, CreditDay, ProjectRate, ProjectStatus, PayCondition, ProjectSize, MethoPay, ClientCNS, BusinessCNS, StartingDate, Code, TextDescription,TextDescriptionMore, WeekRate, IVAPay, FabricName, CantRate, PriceRate, TotalRate, SubTotal, IVAPayTotal, TotalOperation, Add, Remover} from '../../../constant'
import { classes } from '../../../data/layouts';
// import FormComponent from "./FormComponent"
// import { Switch } from 'antd';


const Newproject = (props) => {

  const dispatch = useDispatch()
  const { handleSubmit, formState: { errors } } = useForm();
  const [startDate, setstartDate] = useState(new Date())
  // const [endDate, setendDate] = useState(new Date());
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  // const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date,setDate] = useState([])
  const [dato,setDato] = useState([])
  const [dataCompany, setDataCompany]= useState([])
  const Cotizacion = useNavigate();

  const [empresa, setEmpresa] = useState();
  const [customer_id, setCustomer_id] = useState();
  // const [seller_id, setVendedor] = useState();
  // const [fecha, setFecha] = useState();
  const [condicion_pago, setCondicion_pago] = useState();
  const [condicion_credito, setCondicion_credito] = useState();
  const [metodo_pago, setMetodo_pago] = useState();
  const [dias_validacion, setDias_validacion] = useState();
  // const [codigo, setCodigo] = useState();
  // const [producto, setProducto] = useState();
  // const [descripcion, setDescripcion] = useState();
  // const [cantidad, setCantidad] = useState();
  // const [precio_unitario, setPrecio_unitario] = useState();
  // const [totales, setTotales] = useState();
  const [semanas_validacion, setSemanas_validacion] = useState();
  const [fabrica, setFabrica] = useState();

    // const [images, setImages] = useState([]);
  // const [sub_total, setSub_total] = useState();
  // const [iva_total, setIva_total] = useState();
  // const [total_operacion, setTotal_operacion] = useState();
  // const [precio, setPrecio] = useState();
  // const [cantidades, setCantidades] = useState();
  // const [total, setTotal] = useState();
  const [subTotal, setSubtotal] = useState();
  // const [ivas, setIvas] = useState();
  const [totalidad, setTotalidad] = useState();
  const [iva, setIva] = useState();
/****************************************************** */
  const [product, setProduct] = useState([
    {
      name:"",
      code:"",
      description:"",
      /*images:"",*/
      amount:"",
      price: "",
      total:"",
    }
  ]);

/****************************************************** */

  const handleChangeProdut = (index) => (e) => setProduct(prev => {
      const { name, value } = e.target;
      const newProducts = [...prev];

      newProducts[index] = {...newProducts[index], [name]: value};
      newProducts[index].total = newProducts[index].price * newProducts[index].amount;

      return newProducts;
    })

    useEffect(() => {
      const subTotal = product.reduce((subTotal, product) => subTotal + product.total, 0)
      setSubtotal(subTotal);
    }, [product])

    useEffect(() => {
      const totalidad = subTotal + (iva*subTotal);
      setTotalidad(totalidad);
    }, [iva,subTotal])

  const handleRemove = (index) => (e) => setProduct(prev => prev.filter((_, indexProduct) => indexProduct !== index))

  const handleChangeIva = (e) => {
    setIva(e.target.value)
  }

/****************************************************** */



  const handleStartDate = e => {
    setstartDate(e);
  };
  const SaveQuotation = () => {
    let formData = {
      "customer_id": +customer_id,
      "details": {
        "company_options_id": empresa,
        "condition_pay": condicion_pago,
        /*"condition_credit":condicion_credito,*/
        "method_pay": metodo_pago,
        "date": format(startDate, "yyyy-MM-dd"),
        "validations_day": dias_validacion,
        "validations_weeks": semanas_validacion,
        "factory": fabrica||'',
        "iva": iva*subTotal,
        "sub_total": subTotal,
        "total_amount": totalidad,
      },
      "product": product,
    }

    console.log(formData);
    axios.post('/quotation',
   formData,
    {
        headers: {'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`},
    },
    )
    .then(function (formData) {
        setTimeout(() => {
          toast.success("Cotización realizada exitosamente");
        }, 200);
        Cotizacion(`${process.env.PUBLIC_URL}/app/ecommerce/OrderHistory/${layout}`, {replace: true});
    })
    .catch(function (error) {
      setTimeout(() => {
        toast.error("Faltaron datos en la Cotización");
      }, 200);
    })

  };

  //funcion para quitar decimales
  // function financial(x) {
  //   return Number.parseFloat(x).toFixed(2);
  // }


  // called every time a file's `status` changes
  // const handleChangeStatus = ({ meta, file }, status) => { }

  const AddProject = data => {
    if (data !== '') {
      dispatch(addNewProject(data))
      props.history(`${process.env.PUBLIC_URL}/app/project/project-list/${layout}`)
    } else {
      errors.showMessages();
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const rsocial = await axios.get('/customers?page=1&size=50');
      setDate(rsocial.data.items);
      setLoading(false);
    }
    fetchPosts();
},[]);

useEffect(() => {
  const fetchPosts = async () => {
    setLoading(true);
    const name = await axios.get('/company?page=1&size=50');
    setDataCompany(name.data.items);
    setLoading(false);
  }
  fetchPosts();
},[]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const name = await axios.get('/users?page=1&size=50');
      setDato(name.data.items);
      setLoading(false);
    }
    fetchPosts();
  },[]);


  return (
    <Fragment>
      <Breadcrumb parent="Project" title="Crear Cotización" />
      <Container fluid={true} className="projectlist">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="theme-form" onSubmit={handleSubmit(AddProject)}>
                  <Row>
                  {/* Empresa del Corporativo CNS */}
                  <Col sm="12">
                      <FormGroup>
                        <Label>{ProjectSize}</Label>
                        <Input onChange={(e) => setEmpresa(e.target.value)} type="select" name="status" placeholder="Select Status" className="form-control digits" >
                          <option>{BusinessCNS}</option>
                          {dataCompany.map((items,i) =>
                            <option value={items.id} key={i}>{items.name}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* Nombre del Cliente - Nombre del Vendedor */}
                  <Row>
                  <Col sm="12">
                      <FormGroup>
                        <Label>{ClientName}</Label>
                        <Input onChange={(e) => setCustomer_id(e.target.value)} type="select" name="status" placeholder="Select Status" className="form-control digits" >
                          <option>{ClientCNS}</option>
                          {date.map((items,i) =>
                            <option value={items.id} key={i}>{items.rsocial}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                    {/* <Col sm="2">
                      <FormGroup>
                        <Label>{SellerName}</Label>
                        <Input onChange={(e) => setVendedor(e.target.value)} type="select" name="status" placeholder="Select Status" className="form-control digits" >
                          <option>{ClientCNS}</option>
                          {dato.map((items,i) =>
                            <option value={items.id} key={i}>{items.name}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col> */}
                  </Row>
                  {/* Emision - validez de la cotizacion - condiciones de pago */}
                  <Row>
                  <Col sm="3">
                      <FormGroup>
                        <Label>{StartingDate}</Label>
                        <DatePicker className="datepicker-here form-control" selected={startDate}
                          onChange={(e) => {
                            handleStartDate(e)
                            // setFecha(e.target.value)
                          }}></DatePicker>
                      </FormGroup>
                    </Col>
                  <Col sm="2">
                       <Label>{PayCondition}</Label>
                       <Input onChange={(e) => setCondicion_pago(e.target.value)} type="select" name="progress_level" className="form-control digits" >
                         <option>{"-- selec.. --"}</option>
                         <option>{"Contado"}</option>
                         <option>{"Credito"}</option>
                         <option>{"Pre - Pagado"}</option>
                       </Input>
                    </Col>
                    {/* <Col sm="2">
                       <Label>{CreditDay}</Label>
                       <Input onChange={(e) => setCondicion_credito(e.target.value)} type="number" name="progress_level" className="form-control digits" >
                         <option>{"-- selec.. --"}</option>
                         <option>{"Contado"}</option>
                         <option>{"Credito"}</option>
                         <option>{"Pre - Pagado"}</option>
                       </Input>
                    </Col> */}
                  <Col sm="2">
                     <FormGroup>
                       <Label>{MethoPay}</Label>
                       <Input onChange={(e) => setMetodo_pago(e.target.value)} type="select" name="MethoPay" className="form-control digits" >
                         <option>{"-- selec.. --"}</option>
                         <option>{"Transferencia Bancaria - Bolivares"}</option>
                         <option>{"Divisas USD"}</option>
                         <option>{"A convenir"}</option>
                       </Input>
                     </FormGroup>
                    </Col>
                  <Col sm="3">
                      <FormGroup>
                        <Label>{ProjectRate}</Label>
                        <Input className="form-control digits" onChange={(e) => setDias_validacion(e.target.value)} type="number" name="dias_validacion" id='dias_validacion' defaultValue="0" placeholder="Inserte dias" />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* Codigo - Descripción - Descripción Detallada - cantidad - precio unitario - Total  */}
                  {product.map((prod, index) => (
                    <>
                    <h6>Producto {index+ 1}</h6>
                    <Row>
                      <Col sm ="1">
                          <FormGroup>
                            <Label>{Code}</Label>
                            <Input className="form-control" onChange={handleChangeProdut(index)} type="text" name="code" placeholder="Cod" value={prod.code} />
                            {/* {<span style={{ color: "red" }}>{errors.client_name && 'Client Name is required'}</span>} */}
                          </FormGroup>
                        </Col>
                      <Col sm ="4">
                     <FormGroup>
                       <Label>{TextDescription}</Label>
                       <Input className="form-control" onChange={handleChangeProdut(index)} type="text" name="name" placeholder="-- Producto -- "value={prod.name} />
                       <span style={{ color: "red" }}>{errors.descripcion && 'Insterar Datos'}</span>
                       <Col>
                      <div className="mb-0">
                        <Label>{TextDescriptionMore}</Label>
                        <Input className="form-control" onChange={handleChangeProdut(index)} type="textarea" name="description" placeholder="-- Producto -- "value={prod.description} />
                      </div>
                    </Col>
                    <br />
                    {/* <Col>
                      <Label>{Images}</Label>
                        <Input className="form-control" onChange={handleChangeProdut(index)} type="file" name="images" value={prod.images} />
                        <Button type="button" color="primary">
                          <span class="icon">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </span>
                        </Button>
                      </Col> */}
                     </FormGroup>
                   </Col>
                  <Col sm="2">
                      <FormGroup>
                        <Label>{CantRate}</Label>
                        <input className="form-control" onChange={handleChangeProdut(index) } type="number" name="amount" id="amount" defaultValue="0" step="0.01" placeholder="Inserte Cantidad" value={prod.amount} />
                      </FormGroup>
                   </Col>
                  <Col sm="2">
                      <FormGroup>
                        <Label>{PriceRate}</Label>
                        <input className="form-control" onChange={handleChangeProdut(index)} type="number" name="price" id="price" defaultValue="0" step="0.01" placeholder="Inserte Valor" value={prod.price}/>
                      </FormGroup>
                   </Col>
                  <Col sm="2">
                    <FormGroup>
                      <Label>{TotalRate}</Label>
                      <input className="form-control" onChange={handleChangeProdut(index)} type="number" name="total" id="total" defaultValue="0" step="0.01" placeholder="Inserte Valor" disabled value={prod.total}/>
                      {/* value={financial(getValues().total)} */}
                    </FormGroup>
                   </Col>
                   {index !== 0 && <Col sm="1">
                    <Label>{Remover}</Label>
                    <Button type="button" class="delete" onClick={handleRemove(index)} color="danger">
                      <span class="icon">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </Button>
                  </Col>}
                  </Row>
                  </>
                  ))}
                  <Row>
                   <Col sm="1">
                    <Label>{Add}</Label>
                    <Button type="button" color="primary" onClick={() => setProduct(prev => [...prev, {name:"",code: "",description:"",amount:"",price: "",total:"",}])}>
                      <span class="icon">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                      </span>
                    </Button>
                  </Col>
                  </Row>
                  <Label className="me-3 mb-4">{ProjectStatus}</Label>
                  <Row>
                  <Col sm="4">
                      <FormGroup>
                        <Label>{WeekRate}</Label>
                        <Input className="form-control" onChange={(e) => setSemanas_validacion(e.target.value)} type="number" name="rate" defaultValue="1" placeholder="Enter project Rate" value ={semanas_validacion}/>
                      </FormGroup>
                    </Col>
                  <Col sm ="4">
                      <FormGroup>
                        <Label>{FabricName}</Label>
                        <Input className="form-control" onChange={(e) => setFabrica(e.target.value)} type="text" name="client_name" placeholder="-- Selecionar --" value = {fabrica} />
                        {/* <span style={{ color: "red" }}>{errors.Fabric_name && 'Client Name is required'}</span> */}
                      </FormGroup>
                    </Col>
                  <Col sm="4">
                     <FormGroup>
                        <Label>{IVAPay}</Label>
                        <div className="radio radio-primary ms-4" onChange={handleChangeIva}>
                          <Input  type="radio" name="radio" id="radio1" value={0.16} className="m-2"/>
                          <Label for="radio1">{"SI"}</Label>
                          <Input  type="radio" name="radio" id="radio2" value={0} className="m-2"/>
                          <Label for="radio2">{"NO"}</Label>
                        </div>
                     </FormGroup>
                  </Col>
                  </Row>
                  <Row>
                  <Col sm="4">
                      <FormGroup>
                        <Label>{SubTotal}</Label>
                        <Input className="form-control" step="0.01" type="number" name="SubTotal" id="SubTotal" defaultValue="0" placeholder="0" value={(+subTotal).toFixed(2)} disabled />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{IVAPayTotal}</Label>
                        <Input className="form-control" step="0.01" type="number" name="IVAPayTotal" id="IVAPayTotal" defaultValue="0" placeholder="0" disabled value={(iva*subTotal).toFixed(2)}/>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{TotalOperation}</Label>
                        <input className="form-control" step="0.01" type="number" name="TotalOperation" id="TotalOperation" defaultValue="0" placeholder="0" value={(+totalidad).toFixed(2)} disabled />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          inputContent="Drop A File"
                          styles={{
                            dropzone: { width: '100%', height: 50 },
                            dropzoneActive: { borderColor: 'green' },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <FormGroup>
                      {/* <button className="btn btn-primary" type="submit" > Guardar</button> */}
                      <button className="btn btn-primary" type="button" onClick = {(e) => {e.preventDefault(); SaveQuotation()}}> Guardar</button>
                        {/* <Link to={`${process.env.PUBLIC_URL}/app/project/project-list`}>
                          <Button color="danger"className="me-3 mt-3">{Cancel}</Button>
                        </Link> */}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Newproject;