import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
// import { Link } from 'react-router-dom'
import { Container, Row, Col,  CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import {EditProfile,NumberPhone,UsersRolMenu,UpdateProfile,FirstName,LastName,EmailAddress,Rol} from '../../constant';
import { classes } from '../../data/layouts';
const UserEdit = (props) => {
  
  const [data,setData] = useState([])
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  // useEffect(() => {
  //     axios.get(`${process.env.PUBLIC_URL}/api/user-edit-table.json`).then(res => setData(res.data))
  // },[])

  useEffect(() => {
     
    axios.get(`http://192.168.2.231:8000/users`)
      .then(res => {
        setData(res.data)
        return res;
      } )
      .then(res => data)
  },[])

  return (
    <Fragment>
      <Breadcrumb parent="Usuarios" title="Editar Usuarios" />
      <Container fluid={true}>
        <div className="Editar Perfil">
          <Row>
            <Col xl="12">
              <Form className="card">
                <CardHeader>
                  <h4 className="card-title mb-0">{EditProfile}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{FirstName}</Label>
                        <Input className="form-control" type="text" placeholder="Pedro Leonardo" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{LastName}</Label>
                        <Input className="form-control" type="text" placeholder="Sanchez Hernandez" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="form-label">{NumberPhone}</Label>
                        <Input className="form-control" type="text" placeholder="04128765359" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        <Input className="form-control" type="email" placeholder="psanchez@corporacioncns.com" />
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <FormGroup>
                        <Label className="form-label">{Rol}</Label>
                        <Input type="select" name="select" className="form-control btn-square">
                          {UsersRolMenu.map((items,i) => 
                            <option key={i}>{items}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-end">
                  <button className="btn btn-primary" type="submit">{UpdateProfile}</button>
                </CardFooter>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default UserEdit;