import React, { useState, useEffect,} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import man from '../../assets/images/user/user.png';
import { Container, Row, Col, Form, Input, Label, Button, TabContent, TabPane } from 'reactstrap'
// import { firebase_app, Jwt_token } from '../../data/config'
// import { handleResponse } from '../../services/fack.backend'
// import { useAuth0 } from '@auth0/auth0-react'
import axios from '../services/api/axios';
// import { Facebook, GitHub } from 'react-feather'
// import firebaseImg from '../../assets/images/firebase.svg'
// import jwtImg from '../../assets/images/jwt.svg'
// import authImg from '../../assets/images/auth0.svg'
import { Password, SignIn, EmailAddress, RememberPassword } from '../../constant';
// import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { classes } from '../../data/layouts';

const Logins = (props) => {


  // const { loginWithRedirect } = useAuth0()
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState("jwt");
  const [togglePassword, setTogglePassword] = useState(false);
  const Ecommerce = useNavigate();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [value, setValue] = useState(
    localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  useEffect(() => {
    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
  }, [value, name]);

const loginWithJwt = async(e) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: ({ username, password})
  };
  const formData = new FormData();
  formData.set('username', username);
  formData.set('password', password);
  try {
   const res = await axios.post('/auth/jwt/login',
    formData,
    {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },
  )
  setValue(man);
  setName(username);
  localStorage.setItem('token', res.data.access_token);
  setTimeout(() => {
    toast.success("Bienvenido: " + username);
  }, 100);  
  Ecommerce(`${process.env.PUBLIC_URL}/dashboard/ecommerce/${layout}`, {replace: true});
  window.location.reload(true);
  // window.location.href = `${process.env.PUBLIC_URL}/dashboard/ecommerce/${layout}`;
  } catch (error) {
    setTimeout(() => {
      toast.error("Oppss.. Usuario o Contraseña Invalido");
    }, 100);    
  // alert("Usuario o Contraseña Invalido")
  }
}


  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img className="img-fluid for-light" src={require("../../assets/images/logo/login.png")} alt="" />
                  <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="" />
                </a>
              </div>
              <div className="login-main login-tab">
                <TabContent activeTab={selected} className="content-login">
                  <TabPane className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form">
                      <h4>{selected === "firebase" ? "Sign In With Firebase" : "Iniciar Sesión"}</h4>
                      <p>{"Ingresar usuario y contraseña para entrar"}</p>
                      <div className="mb-3">
                        <Label className="col-form-label">{EmailAddress}</Label>
                        <Input className="form-control" type="email" required="" onChange={e => setUsername(e.target.value)} defaultValue={username} />
                      </div>
                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{Password}</Label>
                        <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required="" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div><br />
                      </div>
                      <div className="login-btn mb-0">
                        {/* <div className="checkbox mb-2">
                          <Input id="checkbox1" type="checkbox" />
                          <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                        </div> */}
                        {/* <a className="link" href="../cuba/forgetPwd">{ForgotPassword}</a> */}
                        {selected === "firebase" ?
                          <Button color="primary" disabled={loading ? loading : loading} onClick={(e) => loginWithJwt(username, password)}>{loading ? "LOADING..." : SignIn}</Button>
                          :
                          <Button className="w-100 text-center" color="primary" onClick = {() => loginWithJwt(username, password)}>{loading ? "LOADING..." : SignIn}</Button>
                        }
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

// export default withRouter(Logins);
export default Logins;