import React, { Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,Table, Button} from "reactstrap";
import { BasicTable,InverseTable,InverseTablePrimaryBackground,HoverableRows,ContextualClasses,TextBackgroundUtilities,TableHeadOptions,StripedRow,StripedRowInverseTable,Caption,ResponsiveTables,BreckpointSpecific, ClientTableTitle, LogisticTableHeader} from "../../constant";
import { classes } from '../../data/layouts';
import CustomPagination from '../application/pagination/paginations'
import { Link } from 'react-router-dom'
import axios from 'axios'

const BasicTables = () => {

    const [data,setData] = useState([])
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        const fetchPosts = async () => {
          setLoading(true);
          const res = await axios.get('http://192.168.2.231:8000/customers?page=1&size=10');
          setPosts(res.data.items);
           
          setLoading(false);
        }
        fetchPosts();
    },[]);

    return (
        <Fragment>
            <Breadcrumb parent="Tables" title="Basic Tables"/>
            <Container fluid={true}>
                <Row>
                <Col md="12">
              <Card>
                <CardHeader>
                    <h5>{TextBackgroundUtilities}</h5>
                    <span>{"Leyenda"}: <span className="bg-primary">{"Entregado"}</span>, <span className="bg-secondary">{"Transito "} <i class="flag-icon flag-icon-ve"></i></span>, <span className="bg-success">{"Venezuela"}</span>, <span className="bg-info">{"Confirmado"}</span>, <span className="bg-warning">{"Transito "}<i class="flag-icon flag-icon-us"></i></span>, <span className="bg-danger">{"Cancelado"}</span>, <span className="bg-light">{"Pendiente"}</span></span>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        {LogisticTableHeader.map((items,i) => 
                          <th key={i}>{items}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map(({id,rif,rsocial,state_id,quote},i) => 
                        <tr key={i}>
                          <td>{id}</td>
                          <td>{rif}</td>
                          <td>{rsocial}</td>
                          <td>{state_id}</td>
                          <td>{quote}</td>
                          <td className="text-end">
                          <Link to={`${process.env.PUBLIC_URL}/app/users/visits/${layout}`}><Button color="primary" size="sm"><i className="fa fa-pencil p-1"></i></Button></Link>
                          </td>
                        </tr>
                      )}
                      <CustomPagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} aria-label="Page navigation example" className="pagination-primary"/ >
                    </tbody>
                  </table>
                </div>
              </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                                <CardHeader>
                                    <h5>{TextBackgroundUtilities}</h5>
                                    <span>{"Leyenda"}: <span className="bg-primary">{"Entregado"}</span>, <span className="bg-secondary">{"Transito "} <i class="flag-icon flag-icon-ve"></i></span>, <span className="bg-success">{"Venezuela"}</span>, <span className="bg-info">{"Confirmado"}</span>, <span className="bg-warning">{"Transito "}<i class="flag-icon flag-icon-us"></i></span>, <span className="bg-danger">{"Cancelado"}</span>, <span className="bg-light">{"Pendiente"}</span></span>
                                </CardHeader>
                                <div className="table-responsive">
                                    <Table className="table-borderedfor">
                                        <thead>
                                            <tr>
                                                <th scope="col">{"#"}</th>
                                                <th scope="col">{"Heading"}</th>
                                                <th scope="col">{"Heading"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-active">
                                                <td className="bg-primary">{"1"}</td>
                                                <td className="bg-primary">{"primary"}</td>
                                                <td className="bg-primary">{"primary"}</td>
                                            </tr>
                                            <tr className="table-active">
                                                <td className="bg-secondary">{"2"}</td>
                                                <td className="bg-secondary">{"secondary"}</td>
                                                <td className="bg-secondary">{"secondary"}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-success">{"3"}</td>
                                                <td className="bg-success">{"success"}</td>
                                                <td className="bg-success">{"success"}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-info">{"4"}</td>
                                                <td className="bg-info">{"info"}</td>
                                                <td className="bg-info">{"info"}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-warning">{"5"}</td>
                                                <td className="bg-warning">{"warning"}</td>
                                                <td className="bg-warning">{"warning"}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-danger">{"6"}</td>
                                                <td className="bg-danger">{"danger"}</td>
                                                <td className="bg-danger">{"danger"}</td>
                                            </tr>
                                            <tr className="table-active">
                                                <td className="bg-light">{"7"}</td>
                                                <td className="bg-light">{"light"}</td>
                                                <td className="bg-light">{"light"}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                        </Card>
                    </Col>
                </Row>
                </Container>
        </Fragment>
            );
        };
        
export default BasicTables;