import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
// import { Link } from 'react-router-dom'
import { Container, Row, Col, CardHeader, CardBody,  Form, Button } from 'reactstrap'
import axios from '../../../pages/services/api/axios'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../../../pages/services/modal/modalquote'
import { HistorialQuotes, QuotationTableHeader} from '../../../constant';

import { classes } from '../../../data/layouts';

const OrderHistory = (props) => {
  
  // const [data,setData] = useState([])
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const CotizacionL = useNavigate();

  const handleDownload = async (data) =>{

    try{
      const res = await axios.get('/quotation-pdf/'+ data,
        {
          headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'blob',
      });

      toast.success("PDF creado exitosamente")

      setTimeout( () => {
        window.open(URL.createObjectURL(res.data))
      },1500)

    } catch (error) {
        setTimeout(() => {
          toast.error("No cumple con los privilegios necesarios");
         }, 100);
    }
    
    // try{
    //   const res = await axios.get('/quotation-pdf/'+quotation_id,{responsiveType: 'arraybuffer'},
    //     {
    //       headers: {
    //       'Authorization': `Bearer ${localStorage.getItem('token')}`
    //     },
    //   });
    //   // window.open = URL.createObjectURL(res.data);
    //   const url = window.URL.createObjectURL(new Blob([res.data],{type: 'arraybuffer'}))
    //   const link = document.createElement('a');
    //   link.href = url; 
    //   link.setAttribute('download' 'resume.pdf');
    //   document.body.appendChild(link);
    //   link.click();
    // } catch (error) {
    //     setTimeout(() => {
    //       toast.error("No cumple con los privilegios necesarios");
    //      }, 100);
    // }

  }

  const handleRemove = async (quotation_id) => {
    try {
    const res = await axios.delete('/quotation/'+quotation_id,{headers: {'Content-Type': 'application/json','Authorization': `Bearer ${localStorage.getItem('token')}`},});
    CotizacionL(`${process.env.PUBLIC_URL}/dashboard/ecommerce/${layout}`, {replace: true});
    setTimeout(() => {
      toast.success("Borrado Exitosamente");
    }, 100);
    } catch (error) {
        setTimeout(() => {
    toast.error("No cumple con los privilegios necesarios");
  }, 100);    
    }
   
}

// const handleRemove = async (quotation_id) => {
  
//   try {
//   const res = await axios.delete('/quotation/'+quotation_id,
//   {
//     headers: {'Content-Type': 'application/json',
//     'Authorization': `Bearer ${localStorage.getItem('token')}`
//   },
    
//   });
  
//   CotizacionL(`${process.env.PUBLIC_URL}/dashboard/ecommerce/${layout}`, {replace: true});
//   setTimeout(() => {
//     toast.success("Borrado Exitosamente");
//   }, 100);
//   } catch (error) {
//       setTimeout(() => {
//   toast.error("No cumple con los privilegios necesarios");
// }, 100);    
//   }
 
// }

const [estadoModal3, cambiarEstadoModal3] = useState(false);
const [resData, setResData] = useState({});

useEffect(() => {
  const fetchPosts = async () => {
    setLoading(true);
    const res = await axios.get('/quotation?page=1&size=50',
  {
      headers: {'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
      
  },);
    setPosts(res.data.items);
    setLoading(false);
  }
  fetchPosts();
},[]);

  return (
    <Fragment>
      <Breadcrumb parent="Clientes" title="Historial de Cotización" />
      <Container fluid={true}>
        <div className="Datos Comerciales">
          <Row>
            <Col xl="12">
              <Form className="card">
                <CardHeader>
                  <h4 className="card-title mb-0">{HistorialQuotes}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        {QuotationTableHeader.map((items,i) => 
                          <th key={i}>{items}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map(({id,customers,product,details:{company_options,condition_pay,method_pay,date,validations_day,validations_weeks,factory,total_amount}},i) =>
                        <tr key={i}>
                          <td>{id}</td>
                          <td>{customers.rsocial}</td>
                          <td><abbr className='w-25' title={product.map(({ name }) => name.slice(0, 15)+ '...').join(" - ")}>{product[0].name.slice(0, 15)+ '...'}</abbr></td>
                          <td>{product.reduce((acc, { amount }) => acc + (+amount), 0)}</td>
                          <td>{total_amount}</td>
                          <td><Button color="success" size="sm" onClick={()=>handleDownload(id)}><i class="fa fa-file-pdf-o" aria-hidden="true"></i></Button></td>
                          {/* <td>
                          <Button onClick={() => {cambiarEstadoModal3(!estadoModal3); setResData({id,details:{company_options,condition_pay,method_pay,date,validations_day,validations_weeks,factory},customers,product})}} color="primary" size="sm"><i className="fa fa-pencil p-1"></i></Button>
                          </td> */}
                          {/* <td>
                            <Button color="danger" size="sm" onClick={()=>handleRemove(id)}><i className="fa fa-trash p-1"></i></Button>
                          </td> */}
                        </tr>
                      )}
                      {/* <CustomPagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} aria-label="Page navigation example" className="pagination-primary"/ > */}
                    </tbody>
                  </table>
                </div>
                </CardBody>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal
       estado ={estadoModal3}
       cambiarEstado = {cambiarEstadoModal3}
       resData = {resData}
      >
      </Modal>
    </Fragment>
  );
}

export default OrderHistory;