import axios from 'axios';
// const BASE_URL = 'http://192.168.2.231/';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  
  // withCredentials: true
});