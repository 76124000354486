import styled from 'styled-components';
import React, { Fragment,useEffect,useState } from 'react';
// import Breadcrumb from '../../../layout/breadcrumb'
// import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from '../api/axios';
import { TRIF,LRIF,Rsocial,Dfiscal,Pcontacto, StateCountry ,NumberPhone, EditProfile,NumberPhone2,UpdateProfile,EmailAddress} from '../../../constant';
import { classes } from '../../../data/layouts';


const Modal = ({estado, cambiarEstado, resData,}) => {

  const [data,setData] = useState([])
  const [identity_id, setIdentity_id] = useState(resData.identity_id);
  const [rif, setRif] = useState(resData.rif);
  const [rsocial, setRsocial] = useState(resData.rsocial);
  const [state_id, setState_id] = useState(resData?.address?.state_id);
  const [personal_contact, setPersonal_contact] = useState(resData.personal_contact);
  const [phone, setPhone] = useState(resData.phone);
  const [second_phone, setSecond_phone] = useState(resData.second_phone);
  const [email, setEmail] = useState(resData.email);
  const [detailedAddress, setDetailedAddress] = useState(resData?.address?.detailedAddress); 

  // const [customer_id, setCustomer_id] = useState(resData.customer_id);
  const [date,setDate] = useState([])
  const Usuario = useNavigate();

  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    if(resData){

      setIdentity_id(resData.identity_id);
      setRif(resData.rif);
      setRsocial(resData.rsocial);
      setState_id(resData?.address?.state_id);
      setPersonal_contact(resData.personal_contact);
      setPhone(resData.phone);
      setSecond_phone(resData.second_phone);
      setEmail(resData.email);
      setDetailedAddress(resData?.address?.detailedAddress);

    }
  }, [resData])
  

  useEffect(() => {
    axios.get(`/rif`)
      .then(rif => {
        setDate(rif.data)
        return rif;
      } )
      .then(frif => date)
  },[])

  useEffect(() => {
    axios.get(`/states`)
      .then(states => {
        setData(states.data)
        return states;
      } )
      .then(states => data)
  },[])

  const SaveUser = (identity_id, rif, rsocial, detailedAddress, personal_contact, state_id, phone, second_phone,email) => {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      body: { identity_id, rif, rsocial, detailedAddress, personal_contact, state_id, phone, second_phone,email }
    };
    
    
    let formData = 
    {
      'identity_id': identity_id,
      'rif': rif,
      'rsocial': rsocial,
      'personal_contact': personal_contact,
      'phone': phone,
      'second_phone': second_phone||'',
      'email': email,
      'address_id': {
        'detailedAddress': detailedAddress,
        'state_id': state_id,
      }
    }

    axios.patch('/customers/'+resData?.id,
    formData,
    {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}`},
    },
    )
    .then(function (formData) {
      setTimeout(() => {
        toast.success("Cliente modificado exitosamente");
      }, 100);
      Usuario(`${process.env.PUBLIC_URL}/dashboard/ecommerce/${layout}`, {replace: true});
    })
    .catch(function (error) {
      setTimeout(() => {
        toast.error("Faltaron datos del Cliente");
      }, 100);
    })
  };

    return (
            <>
            {estado &&
            <Overlay>
                <ContenedorModal>
                    <Fragment>
                    <EncabezadoModal>
                        <h4 className="card-title mb-0">{EditProfile}</h4>
                    </EncabezadoModal>
                      <Container fluid={true}>
                        <div className="Editar Perfil">
                          <Row>
                            <Col xl="12">
                              <Form className="card">
                                <CardBody>
                                  <Row>
                                  <Col md="2">
                                    <FormGroup>
                                      <Label className="form-label">{TRIF}</Label>
                                      <Input className="form-control" type="select" placeholder= {resData?.identity} value={identity_id} name="select" onChange={e => setIdentity_id(e.target.value)}>
                                        {date.map((items,i) => 
                                          <option value={items.id} key={i}>{items.name}</option>
                                        )}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col sm="3" md="3">
                                    <FormGroup>
                                      <Label className="form-label">{LRIF}</Label>
                                      <Input className="form-control" type="text" placeholder= {resData?.rif} value={rif} onChange={e => setRif(e.target.value)} name="eRif" id="eRif"/>
                                    </FormGroup>
                                  </Col>
                                  <Col sm="3" md="7">
                                    <FormGroup>
                                      <Label className="form-label">{Rsocial}</Label>
                                      <Input className="form-control" type="text" placeholder={resData?.rsocial} value={rsocial} required="" onChange={e => setRsocial(e.target.value)} name="Rsocial" id="Rsocial" />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="12" md="12">
                                    <FormGroup>
                                      <Label className="form-label">{Dfiscal}</Label>
                                      <Input className="form-control" type="text" placeholder={resData?.address?.detailedAddress} value={detailedAddress} onChange={e => setDetailedAddress(e.target.value)} name="Dfiscal" id="Dfiscal"/>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                     <FormGroup>
                                       <Label className="form-label">{Pcontacto}</Label>
                                       <Input className="form-control" type="text" placeholder={resData?.personal_contact} value={personal_contact} onChange={e => setPersonal_contact(e.target.value)} name="Pcontacto" id="Pcontacto"/>
                                     </FormGroup>
                                   </Col>
                                  <Col md="6">
                                     <FormGroup>
                                       <Label className="form-label">{StateCountry}</Label>
                                       <Input className="form-control" type="select" name="select" placeholder={resData?.address?.state_id} value={state_id} onChange={e => setState_id(e.target.value)} >
                                         {data.map((items,i) => 
                                           <option value={items.id} key={i}>{items.name}</option>
                                         )}
                                       </Input>
                                     </FormGroup>
                                   </Col>
                                    <Col md="3">
                                      <FormGroup>
                                        <Label className="form-label">{NumberPhone}</Label>
                                        <Input className="form-control" type="text" placeholder= {resData?.phone} value={phone} onChange={e => setPhone(e.target.value)} />
                                      </FormGroup>
                                    </Col>
                                    <Col md="3">
                                      <FormGroup>
                                        <Label className="form-label">{NumberPhone2}</Label>
                                        <Input className="form-control" type="text" placeholder= {resData?.second_phone} value={second_phone} onChange={e => setSecond_phone(e.target.value)} />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6">
                                      <FormGroup>
                                        <Label className="form-label">{EmailAddress}</Label>
                                        <Input disabled className="form-control" type="email" placeholder= {resData?.email} value={email} onChange={e => setEmail(e.target.value)}/>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                                <CardFooter className="text-end">
                                  <button className="btn btn-primary m-1" type="submit" onClick = {
                                (e) => {
                                e.preventDefault(); 
                                SaveUser (identity_id, rif, rsocial, detailedAddress, personal_contact, state_id, phone, second_phone,email)
                                } 
                                }>{UpdateProfile}</button>
                                <button className="btn btn-danger" onClick={() => cambiarEstado(false)}>Cancelar</button>
                                </CardFooter>
                              </Form>
                            </Col>
                          </Row>
                        </div>
                      </Container>
                    </Fragment>
                </ContenedorModal>
            </Overlay>            
        }   
    </>     
    );

}

export default Modal;

const Overlay = styled.div`

overflow: auto;
width: 120vw;
height: 105vh;
position: fixed;
top: 0;
left: 0;
background: rgba(0,0,0,.5);
padding: 50px;
display: flex;
aling-items: center;
justify-content: center;

`;

const ContenedorModal = styled.div`

width: 850px;
min-height: 100px;
background: #fff;
position: relative;
border-radius: 5px;
box-shadow: (100,100,111,0.2) 8px 7px 29px 0px;
padding: 20px;

`;

const EncabezadoModal = styled.div`

display: flex;
aling-items: center;
justify-content: space-between;
margin-bottom: 10px;
padding: 10px;
border-bottom: 1px solid #E8E8E8;

`;

// const BotonCerrar = styled.button`

// position: absolute;
// top: 30px;
// right: 20px;

// width: 30px;
// height: 30px;
// border: none;
// background: none;
// cursor: pointer;
// transition: .3s ease all;
// border-radius: 5px;
// color: #1766DC;

// &:hover{
//     background-color: #F2F2F2;
// }
// `;