import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
// import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, Button, Pagination} from 'reactstrap'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import CustomPagination from '../application/pagination/paginations'
import axios from '../../pages/services/api/axios'
import Modal from '../../pages/services/modal/modalclient'
import {ClientTableHeader, ClientTableTitle} from '../../constant';
import { classes } from '../../data/layouts';
const ClientL = (props) => {
  
  // const [data,setData] = useState([])
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const Borrado = useNavigate();

  useEffect(() => {
      const fetchPosts = async () => {
        setLoading(true);
        const res = await axios.get('/customers?page=1&size=100',
      {
          headers: {'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
          
      },);
        setPosts(res.data.items);
         
        setLoading(false);
      }
      fetchPosts();
  },[]);

    const [resData, setResData] = useState({});

    const handleRemove = async (customer_id) => {
      try {
      const res = await axios.delete('/customers/'+customer_id);
      Borrado(`${process.env.PUBLIC_URL}/dashboard/ecommerce/${layout}`, {replace: true});
      setTimeout(() => {
        toast.success("Borrado Exitosamente");
      }, 100);
      } catch (error) {
          setTimeout(() => {
      toast.error("No cumple con los privilegios necesarios");
    }, 100);    
      }
     
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const [estadoModal2, cambiarEstadoModal2] = useState(false);

  // useEffect(() => {
  //     axios.get(`http://192.168.2.231:8000/customers?page=1&size=10`).then(res => setData(res.data))
  // },[])

  // useEffect(() => {
  //   axios.get(`http://192.168.2.231:8000/customers`).then(res => setData(res.data))
  // },[])

  return (
    <Fragment>
      <Breadcrumb parent="Clientes" title="Lista de Clientes" />
      <Container fluid={true}>
        <div className="Editar Perfil">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{ClientTableTitle}</h4>
                  <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        {ClientTableHeader.map((items,i) => 
                          <th key={i}>{items}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map(({id,identity, rif,rsocial,phone,second_phone,email,address:{detailedAddress, state_id}, personal_contact},i) => 
                        <tr key={i}>
                          <td>{id}</td>
                          <td>{[identity.name , "-" ,rif]}</td>
                          <td>{rsocial}</td>
                          <td>{phone}</td>
                          <td>{email}</td>
                          <td className="text-end">
                          {/* <Button onClick={() => {cambiarEstadoModal2(!estadoModal2); setResData({id,identity,rsocial,phone,second_phone, address:{detailedAddress, state_id}, personal_contact, email,rif})}} color="primary" size="sm"><i className="fa fa-pencil p-1"></i></Button> */}
                          </td>
                          {/* <td><Button color="danger" size="sm" onClick={()=>handleRemove(id)}><i className="fa fa-trash p-1"></i></Button></td> */}
                        </tr>
                      )}
                      <CustomPagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} aria-label="Page navigation example" className="pagination-primary"/>
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal
       estado ={estadoModal2}
       cambiarEstado = {cambiarEstadoModal2}
       resData = {resData}
      >
      </Modal>
    </Fragment>
  );
}
export default ClientL;