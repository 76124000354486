// dashbaord
// import Default from '../components/dashboard/default'
// import Default from '../pages/authentication/login'
import Ecommerce from '../components/dashboard/ecommerce'

// widgets
import GeneralWidget from '../components/widgets/general'
import ChartsWidget from '../components/widgets/charts'

// // ui-kits
// import StateColor from '../components/ui-kits/statecolor'
// import Typography from '../components/ui-kits/typography'
// import Avatars from '../components/ui-kits/avatars'
// import HelperClasses from '../components/ui-kits/helperClasses'
// import Grid from '../components/ui-kits/grid'
// import TagAndPills from '../components/ui-kits/tagAndPills'
// import Progress from '../components/ui-kits/progress'
// import Modal from '../components/ui-kits/modal'
// import Alert from '../components/ui-kits/alert'
// import Popover from '../components/ui-kits/popover'
// import Tooltip from '../components/ui-kits/tooltip'
// import Spinner from '../components/ui-kits/spinner'
// import Dropdown from '../components/ui-kits/dropDown'
// import TabBootstrap from '../components/ui-kits/tabs/tabBootstrap'
// import TabLine from '../components/ui-kits/tabs/tabLine'
// import Accordian from '../components/ui-kits/accordian'
// import Shadow from '../components/ui-kits/shadow'
// import List from '../components/ui-kits/list'

// // bonus-ui
// import Scrolling from '../components/bonus_ui/scrolling'
// import BootstrapNotify from '../components/bonus_ui/bootstrapNotify'
// import Rating from '../components/bonus_ui/rating'
// import Dropzone from '../components/bonus_ui/dropzone'
// import SweetAlert from '../components/bonus_ui/sweetAlert'
// import Tour from '../components/bonus_ui/tour'
// import Sticky from '../components/bonus_ui/sticky'
// import Breadcrumb from '../components/bonus_ui/breadcrumb'
// import Imagecrop from '../components/bonus_ui/imagecrop'
// import RangeSlider from '../components/bonus_ui/rangeSlider'
// import Carousel from '../components/bonus_ui/carousel'
// import Ribbons from '../components/bonus_ui/ribbons'
// import Pagination from '../components/bonus_ui/pagination'
// import DragAndDrop from '../components/bonus_ui/dragAndDrop'
// import UploadImage from '../components/bonus_ui/uploadImage'

// // Cards
// import BasicCards from '../components/bonus_ui/cards/basicCards'
// import ThemeCards from '../components/bonus_ui/cards/themeCards'
// import TabCard from '../components/bonus_ui/cards/tabCard'
// import DraggingCards from '../components/bonus_ui/cards/draggingCards'

// // Timeline
// import Timeline1 from '../components/bonus_ui/timelines/timeline'

// Icons
import FlagIcons from '../components/icons/flagIcons'
import FontAwsomeIcon from '../components/icons/fontAwsomeIcon'
import IcoIcons from '../components/icons/icoIcons'
import ThemifyIcons from '../components/icons/themifyIcons'
import FeatherIcons from '../components/icons/featherIcons'
import WeatherIcons from '../components/icons/weatherIcons'

// Buttons 
import DefaultButton from '../components/buttons/default-Button'
import EdgeButton from '../components/buttons/edgeButton'
import FlatButton from '../components/buttons/flatButton'
import GroupButton from '../components/buttons/groupButton'
import RaisedButton from '../components/buttons/raisedButton'


// Forms
import FormValidation from '../components/forms/form-control/form-validation'
import BaseInput from '../components/forms/form-control/baseInput'
import InputGroup from '../components/forms/form-control/inputGroup'
import MegaOption from '../components/forms/form-control/megaOption'

// Form Layout
import FormDefault from '../components/forms/form-layout/formDefault'
import FormWizard1 from '../components/forms/form-layout/form-wizard-1/formwizard1'

// Forms widgets
import Datepicker from '../components/forms/form-widget/datepicker'
import Timepicker from '../components/forms/form-widget/timepickerComponent/timepicker'
import Typeahead from '../components/forms/form-widget/typeaheadComponent/typeahead'


// Tabels
import BasicTabels from '../components/tables/basicTable'
import BorderTable from '../components/tables/borderTable'
import SizingTable from '../components/tables/sizingTable'
import StylingTable from '../components/tables/stylingTable'
import DataTable from '../components/tables/dataTable'

// Charts 
import Apexcharts from "../components/charts/apexCharts"
import GoogleCharts from "../components/charts/googleCharts"
import KnobChart from "../components/charts/knobChart"
import Chartsjs from "../components/charts/chartsjs"
import Chartist from "../components/charts/chartistCharts"

// // Gallary
// import ImageGallery from "../components/gallery/imageGallery"
// import ImageWithDesc from "../components/gallery/imageWithDesc"
// import MesonryGallery from "../components/gallery/mesonryGallery"
// import MesonryDesc from "../components/gallery/mesonryDesc"
// import ImageHover from "../components/gallery/imageHover"

// // Blog
// import BlogDetail from "../components/blog/blogDetail"
// import BlogSingle from "../components/blog/blogSingle"
// import BlogPost from "../components/blog/blogPost"

// // Job Search 
// import CardView from "../components/jobSearch/cardView"
// import JobList from "../components/jobSearch/job-list"
// import JobDetail from "../components/jobSearch/job-detail"
// import JobApply from "../components/jobSearch/job-apply"

// // Learning
// import Learninglist from "../components/learning/learning-list"
// import LearningDeatil from "../components/learning/learning-deatil"



// // Calendar
// import BasicCalender from "../components/calendar/basicCalendar"

// Users
import UserProfile from "../components/users/userProfile"
import UserEdit from "../components/users/userEdit"
import UserCards from "../components/users/userCards"

// Client
import ClientCreate from "../components/users/ClientCreate"
import Visits from "../components/users/visits"
import VisitsL from "../components/users/visitsL"

import ClientL from "../components/users/ClientL"

// // Maps
// import GoogleMap from "../components/map/googleMap"

// // Editor
// import CkEditor from "../components/editor/ckEditor"
// import MdeEditor from "../components/editor/mdeEditor"

// Social App 
// import SocialApp from "../components/social-app"

// // FAQ page 
// import FaqComponent from "../components/faq"

// // knowledgebase page 
// import KnowledgebaseComponent from "../components/knowledgebase"

// // Support Ticket
// import SupportTicket from "../components/support-ticket"

// // Sample page 
// import Sample from "../components/sample"

// // Search page 
// import Search from "../components/search"


// E-commerce-app 
// import Product from "../components/application/ecommerce-app/product"
// import ProductDetail from "../components/application/ecommerce-app/productpage"
// import Cart from "../components/application/ecommerce-app/cart"
// import Wishlist from "../components/application/ecommerce-app/wishlist"
// import Productlist from "../components/application/ecommerce-app/productlist"
// import Paymentdetails from "../components/application/ecommerce-app/paymentdetails"
import OrderHistory from "../components/application/ecommerce-app/OrderHistory"
// import Checkout from "../components/application/ecommerce-app/checkout"
// import Invoice from "../components/application/ecommerce-app/invoice"
// import Pricing from "../components/application/ecommerce-app/pricing"

// // Email
// import Email from "../components/application/email-app/emailDefault"

// // Chat 
// import Chat from "../components/application/chat-app"

// // Bookmark
// import Bookmark from "../components/application/bookmark"

// Project app 
import ProjectList from '../components/application/project/project'
import NewProject from '../components/application/project/new-project'

// File Manager App 
import FileManager from '../components/application/file-manager/file-manager'

// // Kanban Board
// import KanbanBoard from '../components/application/kanban-board/kanbanBoard'

// Task 
import TaskApp from "../components/application/task-app"

// // Contact app
// import ContactApp from "../components/application/contact-app"

// todo-app
// import Todo from "../components/application/todo-app"

// todo-firebase-app
// import TodoFirebase from "../components/application/todo-firebase-app"
// import LeafletMapContain from '../components/map/LeafletMap'
import Users from '../components/users/users'
// import { Visits } from '../constant'



export const routes = [
        // { path: `${process.env.PUBLIC_URL}/dashboard/default/Dubai/`, Component: <Default /> },
        // { path: `${process.env.PUBLIC_URL}/login`, Component: <Default /> },
        { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/:layout/`, Component: <Ecommerce /> },

        { path: `${process.env.PUBLIC_URL}/widgets/general/:layout`, Component: <GeneralWidget /> },
        { path: `${process.env.PUBLIC_URL}/widgets/chart/:layout`, Component: <ChartsWidget /> },

        // { path: `${process.env.PUBLIC_URL}/ui-kits/statecolor/:layout`, Component: <StateColor /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/typography/:layout`, Component: <Typography /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/avatar/:layout`, Component: <Avatars /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/helperclass/:layout`, Component: <HelperClasses /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/grid/:layout`, Component: <Grid /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/tagsandpills/:layout`, Component: <TagAndPills /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/progress-bar/:layout`, Component: <Progress /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/modal/:layout`, Component: <Modal /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/alert/:layout`, Component: <Alert /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/popover/:layout`, Component: <Popover /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/:layout`, Component: <Tooltip /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/spinner/:layout`, Component: <Spinner /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/:layout`, Component: <Dropdown /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap/:layout`, Component: <TabBootstrap /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/tab-line/:layout`, Component: <TabLine /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/accordion/:layout`, Component: <Accordian /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/shadow/:layout`, Component: <Shadow /> },
        // { path: `${process.env.PUBLIC_URL}/ui-kits/list/:layout`, Component: <List /> },

        // { path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable/:layout`, Component: <Scrolling /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify/:layout`, Component: <BootstrapNotify /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/rating/:layout`, Component: <Rating /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone/:layout`, Component: <Dropzone /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/sweetAlert/:layout`, Component: <SweetAlert /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/tourComponent/:layout`, Component: <Tour /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes/:layout`, Component: <Sticky /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb/:layout`, Component: <Breadcrumb /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper/:layout`, Component: <Imagecrop /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider/:layout`, Component: <RangeSlider /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/carousel/:layout`, Component: <Carousel /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons/:layout`, Component: <Ribbons /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/pagination/:layout`, Component: <Pagination /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp/:layout`, Component: <DragAndDrop /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload/:layout`, Component: <UploadImage /> },

        // { path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards/:layout`, Component: <BasicCards /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards/:layout`, Component: <ThemeCards /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard/:layout`, Component: <TabCard /> },
        // { path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards/:layout`, Component: <DraggingCards /> },

        // { path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1/:layout`, Component: <Timeline1 /> },

        { path: `${process.env.PUBLIC_URL}/icons/flagIcons/:layout`, Component: <FlagIcons /> },
        { path: `${process.env.PUBLIC_URL}/icons/fontAwsomeIcon/:layout`, Component: <FontAwsomeIcon /> },
        { path: `${process.env.PUBLIC_URL}/icons/icoIcons/:layout`, Component: <IcoIcons /> },
        { path: `${process.env.PUBLIC_URL}/icons/featherIcons/:layout`, Component: <FeatherIcons /> },
        { path: `${process.env.PUBLIC_URL}/icons/themifyIcons/:layout`, Component: <ThemifyIcons /> },
        { path: `${process.env.PUBLIC_URL}/icons/weatherIcons/:layout`, Component: <WeatherIcons /> },
        { path: `${process.env.PUBLIC_URL}/buttons/default-btn/:layout`, Component: <DefaultButton /> },
        { path: `${process.env.PUBLIC_URL}/buttons/flatBtn/:layout`, Component: <FlatButton /> },
        { path: `${process.env.PUBLIC_URL}/buttons/edgeBtn/:layout`, Component: <EdgeButton /> },
        { path: `${process.env.PUBLIC_URL}/buttons/raisedBtn/:layout`, Component: <RaisedButton /> },
        { path: `${process.env.PUBLIC_URL}/buttons/groupBtn/:layout`, Component: <GroupButton /> },

        { path: `${process.env.PUBLIC_URL}/forms/form-validation/:layout`, Component: <FormValidation /> },
        { path: `${process.env.PUBLIC_URL}/forms/baseInput/:layout`, Component: <BaseInput /> },
        { path: `${process.env.PUBLIC_URL}/forms/inputGroup/:layout`, Component: <InputGroup /> },
        { path: `${process.env.PUBLIC_URL}/forms/megaOptions/:layout`, Component: <MegaOption /> },
        // { path: `${process.env.PUBLIC_URL}/forms/radio-checkbox/:layout`, Component: <CheckboxandRadio /> },
        { path: `${process.env.PUBLIC_URL}/form-layout/formDefault/:layout`, Component: <FormDefault /> },
        { path: `${process.env.PUBLIC_URL}/form-layout/formWizard/:layout`, Component: <FormWizard1 /> },

        { path: `${process.env.PUBLIC_URL}/form-widget/datepicker/:layout`, Component: <Datepicker /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/timepicker/:layout`, Component: <Timepicker /> },
        { path: `${process.env.PUBLIC_URL}/form-widget/typeahead/:layout`, Component: <Typeahead /> },

        { path: `${process.env.PUBLIC_URL}/table/basic/:layout`, Component: <BasicTabels /> },
        { path: `${process.env.PUBLIC_URL}/table/border/:layout`, Component: <BorderTable /> },
        { path: `${process.env.PUBLIC_URL}/table/sizing/:layout`, Component: <SizingTable /> },
        { path: `${process.env.PUBLIC_URL}/table/styling/:layout`, Component: <StylingTable /> },
        { path: `${process.env.PUBLIC_URL}/table/datatable/:layout`, Component: <DataTable /> },

        { path: `${process.env.PUBLIC_URL}/charts/apexCharts/:layout`, Component: <Apexcharts /> },
        { path: `${process.env.PUBLIC_URL}/charts/googleChart/:layout`, Component: <GoogleCharts /> },
        { path: `${process.env.PUBLIC_URL}/charts/knobChart/:layout`, Component: <KnobChart /> },
        { path: `${process.env.PUBLIC_URL}/charts/chartJs/:layout`, Component: <Chartsjs /> },
        { path: `${process.env.PUBLIC_URL}/charts/chartist/:layout`, Component: <Chartist /> },

        // { path: `${process.env.PUBLIC_URL}/app/gallery/imageGallery/:layout`, Component: <ImageGallery /> },
        // { path: `${process.env.PUBLIC_URL}/app/gallery/imageWithDesc/:layout`, Component: <ImageWithDesc /> },
        // { path: `${process.env.PUBLIC_URL}/app/gallery/mesonryGallery/:layout`, Component: <MesonryGallery /> },
        // { path: `${process.env.PUBLIC_URL}/app/gallery/mesonryDesc/:layout`, Component: <MesonryDesc /> },
        // { path: `${process.env.PUBLIC_URL}/app/gallery/imageHover/:layout`, Component: <ImageHover /> },

        // { path: `${process.env.PUBLIC_URL}/app/blog/blogDetail/:layout`, Component: <BlogDetail /> },
        // { path: `${process.env.PUBLIC_URL}/app/blog/blogSingle/:layout`, Component: <BlogSingle /> },
        // { path: `${process.env.PUBLIC_URL}/app/blog/blogPost/:layout`, Component: <BlogPost /> },

        // { path: `${process.env.PUBLIC_URL}/app/jobSearch/cardView/:layout`, Component: <CardView /> },
        // { path: `${process.env.PUBLIC_URL}/app/jobSearch/job-list/:layout`, Component: <JobList /> },
        // { path: `${process.env.PUBLIC_URL}/app/jobSearch/job-detail/:layout`, Component: <JobDetail /> },
        // { path: `${process.env.PUBLIC_URL}/app/jobSearch/job-apply/:layout`, Component: <JobApply /> },

        // { path: `${process.env.PUBLIC_URL}/app/learning/learning-list/:layout`, Component: <Learninglist /> },
        // { path: `${process.env.PUBLIC_URL}/app/learning/learning-detail/:layout`, Component: <LearningDeatil /> },

        { path: `${process.env.PUBLIC_URL}/app/users/userProfile/:layout`, Component: <UserProfile /> },
        { path: `${process.env.PUBLIC_URL}/app/users/userEdit/:layout`, Component: <UserEdit /> },
        { path: `${process.env.PUBLIC_URL}/app/users/users/:layout`, Component: <Users /> },
        { path: `${process.env.PUBLIC_URL}/app/users/ClientCreate/:layout`, Component: <ClientCreate /> },
        { path: `${process.env.PUBLIC_URL}/app/users/visits/:layout`, Component: <Visits /> },
        { path: `${process.env.PUBLIC_URL}/app/users/visitsL/:layout`, Component: <VisitsL /> },
        { path: `${process.env.PUBLIC_URL}/app/users/ClientL/:layout`, Component: <ClientL /> },
        { path: `${process.env.PUBLIC_URL}/app/users/userCards/:layout`, Component: <UserCards /> },

        // { path: `${process.env.PUBLIC_URL}/app/calendar/basic-calendar/:layout`, Component: <BasicCalender /> },

        // { path: `${process.env.PUBLIC_URL}/app/map/googleMap/:layout`, Component: <GoogleMap /> },
        // { path: `${process.env.PUBLIC_URL}/app/map/leafletMap/:layout`, Component: <LeafletMapContain /> },

        // { path: `${process.env.PUBLIC_URL}/app/editor/ckEditor/:layout`, Component: <CkEditor /> },
        // { path: `${process.env.PUBLIC_URL}/app/editor/mdeEditor/:layout`, Component: <MdeEditor /> },

        // { path: `${process.env.PUBLIC_URL}/app/social-app/:layout`, Component: <SocialApp /> },
        // { path: `${process.env.PUBLIC_URL}/app/faq/:layout`, Component: <FaqComponent /> },
        // { path: `${process.env.PUBLIC_URL}/app/knowledgebase/:layout`, Component: <KnowledgebaseComponent /> },
        // { path: `${process.env.PUBLIC_URL}/app/support-ticket/:layout`, Component: <SupportTicket /> },


        // { path: `${process.env.PUBLIC_URL}/pages/samplepage/:layout`, Component: <Sample /> },
        // { path: `${process.env.PUBLIC_URL}/pages/searchpage/:layout`, Component: <Search /> },


        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/product/:layout`, Component: <Product /> },
        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/:id/:layout`, Component: <ProductDetail /> },
        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/cart/:layout`, Component: <Cart /> },
        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist/:layout`, Component: <Wishlist /> },
        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/checkout/:layout`, Component: <Checkout /> },
        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice/:layout`, Component: <Invoice /> },
        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list/:layout`, Component: <Productlist /> },
        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details/:layout`, Component: <Paymentdetails /> },
        { path: `${process.env.PUBLIC_URL}/app/ecommerce/OrderHistory/:layout`, Component: <OrderHistory /> },
        // { path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing/:layout`, Component: <Pricing /> },

        // { path: `${process.env.PUBLIC_URL}/app/email-app/:layout`, Component: <Email /> },
        // { path: `${process.env.PUBLIC_URL}/app/chat-app/:layout`, Component: <Chat /> },
        // { path: `${process.env.PUBLIC_URL}/app/bookmark/:layout`, Component: <Bookmark /> },
        { path: `${process.env.PUBLIC_URL}/app/task/:layout`, Component: <TaskApp /> },

        { path: `${process.env.PUBLIC_URL}/app/project/project-list/:layout`, Component: <ProjectList /> },
        { path: `${process.env.PUBLIC_URL}/app/project/new-project/:layout`, Component: <NewProject /> },

        { path: `${process.env.PUBLIC_URL}/app/file-manager/:layout`, Component: <FileManager /> },
        // { path: `${process.env.PUBLIC_URL}/app/kanban-board/:layout`, Component: <KanbanBoard /> },

        // { path: `${process.env.PUBLIC_URL}/app/contact/:layout`, Component: <ContactApp /> },

        // { path: `${process.env.PUBLIC_URL}/app/todo-app/todo/:layout`, Component: <Todo /> },
        // { path: `${process.env.PUBLIC_URL}/app/todo-app/todo-firebase/:layout`, Component: <TodoFirebase /> },
]