import React from 'react';
import Routers from './route';
import { Provider } from 'react-redux';
import store from './store'
import { useEffect } from 'react';
// import { Auth0Provider } from '@auth0/auth0-react';
// import { auth0 } from './data/config';

const App = () => {
  
  useEffect(() => {
    localStorage.setItem('layout', 'Dubai')
  }, [])

  return(
    <div className="App">
      <Provider store={store}>
        <Routers />
      </Provider>
    </div>
  )
};

export default App;
