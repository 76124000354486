import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
// import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { button, notification, Space } from 'antd';
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input} from 'reactstrap'
import axios from '../../pages/services/api/axios'
import { NumberPhone,NumberPhone2, EmailAddress, EditClient, TRIF, LRIF,  Rsocial, Dfiscal, Pcontacto, StateCountry} from '../../constant';
import { classes } from '../../data/layouts';
import { Select } from 'antd';

const ClientCreate = (props) => {
  
  const [data,setData] = useState([])
  const [date,setDate] = useState([])
  const [identity_id, setIdentity_id] = useState();
  const [rif, setRif] = useState();
  const [rsocial, setRsocial] = useState();
  const [detailedAddress, setDetailedAddress] = useState();
  const [personal_contact, setPersonal_contact] = useState();
  const [state_id, setState_id] = useState();
  const [phone, setPhone] = useState();
  const [second_phone, setSecond_phone] = useState();
  const [email, setEmail] = useState();
  const Cliente = useNavigate();
  const { formState: { errors }, } = useForm();

  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const SaveCustomer = (identity_id, rif, rsocial, detailedAddress, personal_contact, state_id, phone, second_phone, email ) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { identity_id, rif, rsocial, detailedAddress, personal_contact, state_id, phone, second_phone, email }
    };
    
    
    let formData = {

      'identity_id': identity_id,
      'rif': rif,
      'rsocial': rsocial,
      'personal_contact': personal_contact,
      'phone': phone,
      'second_phone': second_phone||'',
      'email': email,
      'address': {
        'detailedAddress': detailedAddress,
        'state_id': state_id,
      }
    }

    axios.post('/customers',
    formData,
    {
        headers: {'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
        
    },
    )
    .then(function (formData) {
      setTimeout(() => {
        toast.success("Cliente creado exitosamente");
      }, 100);
      Cliente(`${process.env.PUBLIC_URL}/app/users/ClientL/${layout}`, {replace: true});
    })
    .catch(function (error) {
      setTimeout(() => {
        toast.error("Faltaron datos del Cliente");
      }, 100);
    })

  };

  useEffect(() => {

    axios.get(`/rif`)
      .then(rif => {

        setDate(rif.data)
        return rif;
      } )
      .then(rif => date)
  },[])

  useEffect(() => {

    axios.get(`/states`)
      .then(states => {

        setData(states.data)
        return states;
      } )
      .then(states => data)
  },[])

  
  return (
    <Fragment>
      <Breadcrumb parent="Clientes" title="Crear Cliente" />
      <Container fluid={true}>
        <div className="Datos Comerciales">
          <Row>
            <Col xl="12">
              <Form className="card">
                <CardHeader>
                  <h4 className="card-title mb-0">{EditClient}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                  <Col md="2">
                      <FormGroup>
                        <Label className="form-label">{TRIF}</Label>
                        <Input className="form-control" type="select" name="select" onChange={e => setIdentity_id(e.target.value)}>
                        <option value="">Tipo de RIF</option>
                          {date.map((items, i) => 
                        <option value={items.id} key={i}>{items.name}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="3" md="3">
                      <FormGroup>
                        <Label className="form-label">{LRIF}</Label>
                        <Input className="form-control" type="text" placeholder="Ej: 11222333-4" required="" onChange={e => setRif(e.target.value)} name="eRif" id="eRif"/><span style={{ color: "red" }}>{errors.eRIF && 'Client Name is required'}</span>
                      </FormGroup>
                    </Col>
                    <Col sm="3" md="7">
                      <FormGroup>
                        <Label className="form-label">{Rsocial}</Label>
                        <Input className="form-control" type="text" placeholder="Ej: MULTISERVICIOS NISPERO AUTOMATIC, C.A" required="" onChange={e => setRsocial(e.target.value)} name="Rsocial" id="Rsocial" /><span style={{ color: "red" }}>{errors.Rsocial && 'Client Name is required'}</span>
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12">
                      <FormGroup>
                        <Label className="form-label">{Dfiscal}</Label>
                        <Input className="form-control" type="text" placeholder="AV 100, BOLIVAR NORTE NRO. 152-240 LOCAL NRO 1 URB EL RECREO" onChange={e => setDetailedAddress(e.target.value)} name="Dfiscal" id="Dfiscal"/><span style={{ color: "red" }}>{errors.Dfiscal && 'Client Name is required'}</span>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="form-label">{Pcontacto}</Label>
                        <Input className="form-control" type="text" placeholder="Pedro Pérez" onChange={e => setPersonal_contact(e.target.value)} name="Pcontacto" id="Pcontacto"/><span style={{ color: "red" }}>{errors.Pcontacto && 'Client Name is required'}</span>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="form-label">{StateCountry}</Label>
                        <Input className="form-control" type="select" name="select" onChange={e => setState_id(e.target.value)} >
                          {data.map((items,i) => 
                            <option value={items.id} key={i}>{items.name}</option>
                          )}
                        </Input><span style={{ color: "red" }}>{errors.StateCountry && 'Client Name is required'}</span>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label className="form-label">{NumberPhone}</Label>
                        <Input className="form-control" type="text" placeholder="04128765359" onChange={e => setPhone(e.target.value)} name="NumberPhone" id="NumberPhone"/><span style={{ color: "red" }}>{errors.NumberPhone && 'Client Name is required'}</span>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label className="form-label">{NumberPhone2}</Label>
                        <Input className="form-control" type="text" placeholder="04128765359" onChange={e => setSecond_phone(e.target.value)} name="NumberPhone2" id="NumberPhone2"/><span style={{ color: "red" }}>{errors.NumberPhone2 && 'Client Name is required'}</span>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        <Input className="form-control" type="email" placeholder="psanchez@corporacioncns.com" onChange={e => setEmail(e.target.value)} name="EmailAddress" id="EmailAddress"/><span style={{ color: "red" }}>{errors.EmailAddress && 'Client Name is required'}</span>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-end">
                <button className="btn btn-primary" type="button" onClick = {
                    (e) => {
                        e.preventDefault(); 
                        SaveCustomer(identity_id, rif, rsocial, detailedAddress, personal_contact, state_id, phone, second_phone,email)
                      } 
                  }> Guardar</button>
                </CardFooter>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default ClientCreate;