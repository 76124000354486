import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
// import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Card, CardHeader, Button, Pagination} from 'reactstrap'
import CustomPagination from '../application/pagination/paginations'
import axios from '../../pages/services/api/axios';
import Modal from '../../pages/services/modal/modaluser'
import {UsersTableTitle,UsersTableHeader,} from '../../constant';
import { classes } from '../../data/layouts';
const Users = (props) => {
  
  // const [data,setData] = useState([])
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
      const fetchPosts = async () => {
        setLoading(true);
        const res = await axios.get('/users?page=1&size=50',
        {
          headers: {'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
          
      },);
        setPosts(res.data.items);
        setLoading(false);
      }
      fetchPosts();
  },[]);

  const [resData, setResData] = useState({});

  const handleUpdate = async (customer_id) => {
    try {
    const res = await axios.get('/users/'+customer_id,
    {
      headers: {'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  },);
  //setResData(res.data.items)
    } catch (error) {
        setTimeout(() => {
    toast.error("No cumple con los privilegios necesarios");
  }, 100);    
    }

}

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const [estadoModal1, cambiarEstadoModal1] = useState(false);

  return (
    <Fragment>
      <Breadcrumb parent="Usuarios" title="Lista de Usuarios" />
      <Container fluid={true}>
        <div className="Editar Perfil">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{UsersTableTitle}</h4>
                  <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        {UsersTableHeader.map((items,i) => 
                          <th key={i}>{items}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map(({id,name,lastname,phone,email,rol_id},i) => 
                        <tr key={i}>
                          <td>{id}</td>
                          <td>{name}</td>
                          <td>{lastname}</td>
                          <td>{email}</td>
                          <td>{phone}</td>
                          <td>{rol_id}</td>
                          <td className="text-end">
                            {/* <Button onClick={() => {cambiarEstadoModal1(!estadoModal1); handleUpdate(id);setResData({id,name,lastname,phone,email,rol_id})}} color="primary" size="sm"><i className="fa fa-pencil p-1"></i></Button> */}
                          {/* <Link to={`${process.env.PUBLIC_URL}/app/users/userEdit/${layout}`}><Button color="primary" size="sm"><i className="fa fa-pencil p-1"></i></Button></Link> */}
                          </td>
                          {/* <td><Button color="danger" size="sm"><i className="fa fa-trash p-1"></i></Button></td> */}
                        </tr>
                      )}
                      <CustomPagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} aria-label="Page navigation example" className="pagination-primary"/ >
                    </tbody>
                  </table>
                  {/* <CardBody>
                      <Pagination aria-label="Page navigation example" className="pagination-primary" >
                          <PaginationItem><PaginationLink href="#javascript"><span aria-hidden="true">«</span><span className="sr-only">{Previous}</span></PaginationLink></PaginationItem>
                          <PaginationItem><PaginationLink href="#javascript">{"1"}</PaginationLink></PaginationItem>
                          <PaginationItem><PaginationLink href="#javascript">{"2"}</PaginationLink></PaginationItem>
                          <PaginationItem><PaginationLink href="#javascript">{"3"}</PaginationLink></PaginationItem>
                          <PaginationItem><PaginationLink href="#javascript"><span aria-hidden="true">»</span><span className="sr-only">{Next}</span></PaginationLink></PaginationItem>
                      </Pagination>
                    </CardBody> */}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal
       estado ={estadoModal1}
       cambiarEstado = {cambiarEstadoModal1}
       resData = {resData}
      >
      </Modal>
    </Fragment>
  );
}

export default Users;