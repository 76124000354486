import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
// import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { format} from 'date-fns'
import { Container, Row, Col, CardHeader, CardBody, CardFooter,Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from '../../pages/services/api/axios'
import {StartingVisit,Observaciones,ClientName, ClientCNS,Motivo,EditClient} from '../../constant';
import { classes } from '../../data/layouts';

const Visits = (props) => {
  
  const [observaciones, setObservaciones] = useState();
  const [motivo, setMotivo] = useState();
  const [customer_id, setCustomer_id] = useState();
  // const [data,setData] = useState([])
  const [date,setDate] = useState([])
  const [startDate, setstartDate] = useState(new Date())
  const Enviar = useNavigate();

  const { formState: { errors }, } = useForm();

  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const SaveVisits = (customer_id, startDate, motivo,observaciones ) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { customer_id, motivo,observaciones }
    };

    
    let formData = {
      'customer_id':customer_id,
      'reason': motivo,
      'date': format(startDate, "yyyy-MM-dd"),
      'observations': observaciones,
    }

    axios.post('/observation',
    formData,
    {
        headers: {'Content-Type': 'application/json',},
    },
    )
    .then(function (formData) {
      setTimeout(() => {
        toast.success("Visita creada exitosamente");
      }, 100);
      Enviar(`${process.env.PUBLIC_URL}/app/users/visitsL/${layout}`, {replace: true});
    })
    .catch(function (error) {
      setTimeout(() => {
        toast.error("Faltaron datos de la Visita");
      }, 100);
    })
  };

  useEffect(() => {
    const fetchPosts = async () => {
      const rsocial = await axios.get('/customers');
      setDate(rsocial.data.items);
    }
    fetchPosts();
},[]);
    const handleStartDate = e => {
      setstartDate(e);
    };
  
  return (
    <Fragment>
      <Breadcrumb parent="Clientes" title="Crear Cliente" />
      <Container fluid={true}>
        <div className="Datos Comerciales">
          <Row>
            <Col xl="12">
              <Form className="card">
                <CardHeader>
                  <h4 className="card-title mb-0">{EditClient}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                  <Col sm="6">
                      <FormGroup>
                        <Label>{ClientName}</Label>
                        <Input onChange={(e) => setCustomer_id(e.target.value)} type="select" name="status" placeholder="Select Status" className="form-control digits" >
                          <option>{ClientCNS}</option>
                          {date.map((items,i) => 
                            <option value={items.id} key={i}>{items.rsocial}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{StartingVisit}</Label>
                        <DatePicker className="datepicker-here form-control" selected={startDate}
                          onChange={(e) => {
                            handleStartDate(e)
                            // setFecha(e.target.value)
                          }}></DatePicker>
                      </FormGroup>
                    </Col>
                    <Col sm ="6">
                     <FormGroup>
                       <Col>
                      <div className="mb-0">
                        <Label>{Motivo}</Label>
                        <Input onChange={e => setMotivo(e.target.value)} type="text" className="form-control" rows="3" placeholder="Entrega de Mercancia"/>
                      </div>
                    </Col>
                     </FormGroup>
                   </Col>
                   <Col sm ="6">
                     <FormGroup>
                       <Col>
                      <div className="mb-0">
                        <Label>{Observaciones}</Label>
                        <Input onChange={e => setObservaciones(e.target.value)} type="textarea" className="form-control" rows="3" placeholder="-- Descripción de la Visita --"/>
                      </div>
                    </Col>
                     </FormGroup>
                   </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-end">
                  <button className="btn btn-primary" type="button" onClick = {(e) => {e.preventDefault(); SaveVisits(customer_id, startDate, motivo,observaciones)}}> Guardar</button>
                </CardFooter>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Visits;