import styled from 'styled-components';
import React, { Fragment,useEffect,useState } from 'react';
// import Breadcrumb from '../../../layout/breadcrumb'
// import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, CardBody, CardFooter,  Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from '../../../pages/services/api/axios';
import { EditProfile,NumberPhone,UpdateProfile,FirstName,LastName,EmailAddress,Rol_Actual,Rol} from '../../../constant';
import { classes } from '../../../data/layouts';


const Modal = ({estado, cambiarEstado, resData,}) => {

  const [password, setPassword] = useState();
  // const [identity_id, setIdentity_id] = useState();
  const [email, setEmail] = useState(resData.email);
  const [is_active, setIs_active] = useState(resData.is_active);
  const [is_superuser, setIs_superuser] = useState(resData.is_superuser);
  const [is_verified, setIs_verified] = useState(resData.is_verified);
  const [name, setName] = useState(resData.name);
  const [rol_id, setRol_id] = useState(resData.rol_id);
  const [lastname, setLastname] = useState(resData.lastname);
  const [phone, setPhone] = useState(resData.phone);
  const [customer_id, setCustomer_id] = useState(resData.customer_id);
  const [date,setDate] = useState([])
  const Usuario = useNavigate();

  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    if(resData){
      setEmail(resData.email);
      setIs_active(resData.is_active);
      setIs_superuser(resData.is_superuser);
      setIs_verified(resData.is_verified);
      setName(resData.name);
      setRol_id(resData.rol_id);
      setLastname(resData.lastname);
      setPhone(resData.phone);
    }
  }, [resData])

  useEffect(() => {
    axios.get(`/rol`,
    {
      headers: {'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      
    },
    )
      .then(rol => {
        setDate(rol.data.items)
        return rol;
      } )
      .then(frol => date)
  },[])

  const SaveUser = (password, email, is_active, is_superuser, is_verified, name, rol_id, lastname, phone) => {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: { password, email, is_active, is_superuser, is_verified, name, rol_id, lastname, phone }
    };
    
    
    let formData = 
    {
        "email": email,
        "is_active": true,
        "is_superuser": true,
        "is_verified": true,
        "name": name,
        "rol_id": rol_id,
        "lastname": lastname,
        "phone": phone,
    }

    axios.patch('/users/'+resData?.id,
    formData,
    {
        headers: {'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
        
    },
    )
    .then(function (formData) {
      setTimeout(() => {
        toast.success("Usuario modificado exitosamente");
      }, 100);
      Usuario(`${process.env.PUBLIC_URL}/app/users/users/${layout}`, {replace: true});
    })
    .catch(function (error) {
      setTimeout(() => {
        toast.error("Faltaron datos del Usuario");
      }, 100);
    })
  };




    return (
            <>
            {estado &&
            <Overlay>
                <ContenedorModal>
                    <Fragment>
                    <EncabezadoModal>
                        <h4 className="card-title mb-0">{EditProfile}</h4>
                    </EncabezadoModal>
                      <Container fluid={true}>
                        <div className="Editar Perfil">
                          <Row>
                            <Col xl="12">
                              <Form className="card">
                                <CardBody>
                                  <Row>
                                  <Col sm="6" md="6">
                                      <FormGroup>
                                        <Label className="form-label">{Rol_Actual}</Label>
                                        <Input disabled className="form-control" type="text" placeholder= {resData?.rol_id} value={rol_id} onChange={e => setRol_id(e.target.value)}/>
                                      </FormGroup>
                                    </Col>
                                  <Col sm="6" md="6">
                                      <FormGroup>
                                        <Label className="form-label">{EmailAddress}</Label>
                                        <Input disabled className="form-control" type="email" placeholder= {resData?.email} value={email} onChange={e => setEmail(e.target.value)}/>
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6">
                                      <FormGroup>
                                        <Label className="form-label">{FirstName}</Label>
                                        <Input className="form-control" type="text" placeholder={resData?.name} value={name} onChange={e => setName(e.target.value)}/>
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6">
                                      <FormGroup>
                                        <Label className="form-label">{LastName}</Label>
                                        <Input className="form-control" type="text" placeholder={resData?.lastname} value={lastname} onChange={e => setLastname(e.target.value)} />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup>
                                        <Label className="form-label">{NumberPhone}</Label>
                                        <Input className="form-control" type="text" placeholder= {resData?.phone} value={phone} onChange={e => setPhone(e.target.value)} />
                                      </FormGroup>
                                    </Col>
                                    <Col md="5">
                                      <FormGroup>
                                        <Label className="form-label">{Rol}</Label>
                                        <Input type="select" name="select" className="form-control btn-square" onChange={e => setRol_id(e.target.value)}>
                                          {date.map((items,i) => 
                                            <option value={items.id} key={i}>{items.name}</option>
                                          )}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                                <CardFooter className="text-end">
                                  <button className="btn btn-primary m-1" type="submit" onClick = {
                                (e) => {
                                e.preventDefault(); 
                                SaveUser (password, email, is_active, is_superuser, is_verified, name, rol_id, lastname, phone, customer_id)
                                } 
                                }>{UpdateProfile}</button>
                                <button className="btn btn-danger" onClick={() => cambiarEstado(false)}>Cancelar</button>
                                </CardFooter>
                              </Form>
                            </Col>
                          </Row>
                        </div>
                      </Container>
                    </Fragment>
                </ContenedorModal>
            </Overlay>            
        }   
    </>     
    );

}

export default Modal;

const Overlay = styled.div`

width: 100vw;
height: 100vh;
position: fixed;
top: 0;
left: 0;
background: rgba(0,0,0,.5);
padding: 40px;
display: flex;
aling-items: center;
justify-content: center;

`;

const ContenedorModal = styled.div`

width: 500px;
min-height: 100px;
background: #fff;
position: relative;
border-radius: 5px;
box-shadow: (100,100,111,0.2) 8px 7px 29px 0px;
padding: 20px;

`;

const EncabezadoModal = styled.div`

display: flex;
aling-items: center;
justify-content: space-between;
margin-bottom: 20px;
padding: 20px;
border-bottom: 1px solid #E8E8E8;

`;

// const BotonCerrar = styled.button`

// position: absolute;
// top: 30px;
// right: 20px;

// width: 30px;
// height: 30px;
// border: none;
// background: none;
// cursor: pointer;
// transition: .3s ease all;
// border-radius: 5px;
// color: #1766DC;

// &:hover{
//     background-color: #F2F2F2;
// }
// `;